import React from 'react';

const ButtonPairing = ({ deviceId, description, handleClick }) => {
  return (
    <button
      className="pairing-button"
      type="button"
      onClick={e => {
        handleClick(deviceId);
      }}
    >
      {description}
    </button>
  );
};

export default ButtonPairing;
