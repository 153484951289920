import React from 'react';
import { useHistory } from 'react-router-dom';
import Menu from './Menu';
import Photo from './Photo';

const VirtualTour = ({
  backgroundVR,
  setBackgroundVR,
  rotationVR,
  setRotationVR,
  pairedDevice,
  setDevices,
  menuActive,
  setMenuActive,
  config,
  setMessageToSend,
}) => {
  const history = useHistory();

  const openConfig = e => {
    e.preventDefault();
    setDevices([]);
    history.push('/');
  };

  return (
    <React.Fragment>
      <div className="header">
  
      </div>
      <a href="" onClick={e => openConfig(e)}>
        <div className="settings-button"></div>
      </a>
      <Photo
        backgroundVR={backgroundVR}
        rotationVR={rotationVR}
        pairedDevice={pairedDevice}
      />
      <Menu
        backgroundVR={backgroundVR}
        setBackgroundVR={setBackgroundVR}
        setRotationVR={setRotationVR}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        config={config}
        setMessageToSend={setMessageToSend}
        pairedDevice={pairedDevice}
      />
    </React.Fragment>
  );
};

export default VirtualTour;
