import vars from 'config/vars';
import { Component } from 'react';

/**
 * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
 */

class WebSocketWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: vars.websocketUrl,
      ws: null,
      connected: false,
      messageToSend: '',
    };
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.onConnectionChanged = this.onConnectionChanged.bind(this);
  }

  // single websocket instance for the own application and constantly trying to reconnect.
  componentDidMount() {
    this.connect();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messageToSend !== this.props.messageToSend) {
      this.setState({ messageToSend: this.props.messageToSend });
      this.sendMessage(this.props.messageToSend);
    }
    if (this.state.connected !== prevState.connected) {
      this.onConnectionChanged();
    }
  }

  onMessageReceived = message => {
    this.props.onMessageReceived(message);
  };

  onConnectionChanged = () => {
    this.props.onConnectionChanged(this.state.connected);
  };

  sendMessage = message => {
    if (message === '') return;
    const ws = this.state.ws;
    if (ws) {
      //ws.send(JSON.stringify(message))
      ws.send(message);
      //console.log("message sent:" + message);
    } else {
      console.error('WS NOT VALID');
    }
  };

  connect = () => {
    const ws = new WebSocket(this.state.endpoint);
    var connectInterval;
    const timeout = 250;
    let tempTimeout = timeout;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log('connected websocket main component');

      this.setState({ ws: ws });
      this.setState({ connected: true });

      tempTimeout = timeout; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    // websocket onclose event listener
    ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (tempTimeout + timeout) / 1000
        )} second.`,
        e.reason
      );

      this.setState({ connected: false });

      tempTimeout += timeout; //increment retry interval
      connectInterval = setTimeout(this.check(), Math.min(10000, tempTimeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        'Socket encountered error: ',
        err.message,
        'Closing socket'
      );

      ws.close();

      this.setState({ connected: false });
    };

    ws.onmessage = evt => {
      // listen to data sent from the websocket server
      if (evt.type === 'message') {
        const message = JSON.parse(evt.data);
        this.onMessageReceived(message);
        // if (message.type != "setRotation")
        //     console.log("Received: '" + evt.data + "'");
      } else if (evt.type === '') {
        //console.log('Received Binary Message of ' + evt.data.size + ' bytes. Use only text message!');
      }
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    console.log('Checking connection...');
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  render() {
    return null;
  }
}

export default WebSocketWrapper;
