import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonPairing from './ButtonPairing';

const DeviceSelection = ({ pairDevice, devicesFound }) => {
  const history = useHistory();

  const handleCLick = deviceId => {
    pairDevice(deviceId);
    history.push('/vr');
  };

  const closeConfig = e => {
    e.preventDefault();
    history.push('/vr');
  };

  return (
    <React.Fragment>
      <a href="" onClick={e => closeConfig(e)}>
        <div className="exit-button"></div>
      </a>
      <div className="pairing-wrapper">
        {/* {<ButtonPairing deviceId="" description="SCOLLEGA" handleClick={handleCLick}></ButtonPairing>} */}
        <p>Visori disponibili</p>
        {devicesFound.map((device, index) => {
          return (
            <ButtonPairing
              key={index}
              deviceId={device.id}
              description={device.deviceName}
              handleClick={handleCLick}
            ></ButtonPairing>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DeviceSelection;
