import vars from 'config/vars';
import React from 'react';

const ButtonVR = ({
  backgroundVR,
  setBackgroundVR,
  setRotationVR,
  menuActive,
  setMenuActive,
  config,
  setMessageToSend,
  pairedDevice,
}) => {
  const handleCLick = (backgroundImg, idImg, rotation) => {
    let imagePath =
      vars.easy360BaseUrl +
      '/Uploads/Galleries/' +
      backgroundImg.GalleryCode +
      '/md_' +
      backgroundImg.PhotoPath;
    setBackgroundVR(imagePath);
    setRotationVR(rotation);
    setMenuActive(!menuActive);
    setMessageToSend(
      `{"type":"setDeviceImage","payload":{"value":"${idImg}"}}`
    );
  };

  const buttons = config.images.map(button => {
    //console.log("button.rotation ", button.InitialOffset);
    return (
      <div
        key={button.Id}
        className={`button-background ${
          backgroundVR && backgroundVR.includes(button.PhotoPath) && 'active'
        }`}
        onClick={() =>
          menuActive &&
          handleCLick(button, button.Code, `0 ${button.InitialOffset - 180} 0`)
        }
        style={{
          backgroundImage: `url("${vars.easy360BaseUrl}/Uploads/Galleries/${button.GalleryCode}/tn_${button.PhotoPath}")`,
        }}
      >
        <div className="text"></div>
        <div className="text_view_nome">
          <p>{button.Title}</p>
        </div>
        <div className="overlay" />
      </div>
    );
  });

  return buttons;
};

export default ButtonVR;
