import {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
} from './modules/Project';

const Client = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
};

export default Client;
