import React from 'react';
import ButtonVR from './ButtonVR';

const Menu = ({
  backgroundVR,
  setBackgroundVR,
  menuActive,
  setMenuActive,
  config,
  setRotationVR,
  setMessageToSend,
  pairedDevice,
}) => {
  const handleCLick = () => {
    setMenuActive(!menuActive);
    // setTimeout(() => {
    //   setMenuActive(false);
    // }, 10000);
  };

  return (
    <div className={`menu-wrapper ${!menuActive && 'hide'}`}>
      <div
        className={`arrow ${!menuActive && 'hide'}`}
        onClick={() => handleCLick()}
      />
      <p>Cambia la vista</p>
      <div className="button-wrapper">
        <ButtonVR
          backgroundVR={backgroundVR}
          setBackgroundVR={setBackgroundVR}
          setRotationVR={setRotationVR}
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          config={config}
          setMessageToSend={setMessageToSend}
          pairedDevice={pairedDevice}
        />
      </div>
    </div>
  );
};

export default Menu;
