import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import vars from '../config/vars';

const API = () => {
  const httpLink = from([
    new HttpLink({
      uri: vars.apolloUri,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({}),
  });
};

export default API;
